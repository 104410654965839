import classNames from 'classnames';
import React, { useState } from 'react';
import { useIsMobile } from '../../hooks/useIsMobile';
import { getUserFullname } from '../../lib/get_user_fullname';
import { getImgixPath } from '../../lib/imgix';
import { OmniaRoutePaths, OMNIA_URL } from '../../lib/routes';
import { setShopifyImgWidth } from '../../lib/set_shopify_img_width';
import { encodeVariantUrl } from '../../lib/url_encoding';
import { getWaterbodyUrl } from '../../lib/waterbody';
import { FishingReport } from '../../models/fishing_report';
import * as base from '../../styles/base.module.less';
import { Avatar } from '../avatar/avatar';
import OmniaCarousel from '../carousel/omnia_carousel';
import productPlaceholder from '../carousel_product_card/product-placeholder.svg';
import SvgFire from '../svg/fire';
import { WaterbodyImg } from '../waterbody_img/waterbody_img';
import * as styles from './fishing_report_card.module.less';

interface Props {
  fishingReport: FishingReport;
  flickityRef?: (flickity: any) => void;
}

export const FishingReportCard: React.FC<Props> = (props) => {
  const { fishingReport, flickityRef } = props;

  const [showFullComments, setShowFullComments] = useState(false);
  const commentsTooLong =
    fishingReport.comments && !showFullComments && fishingReport.comments.split(' ').length > 40;
  const comments = commentsTooLong
    ? fishingReport.comments.split(' ').splice(0, 40).join(' ').concat('... ')
    : fishingReport.comments;

  const isMobile = useIsMobile();
  const shouldShowArrows = isMobile ? true : fishingReport?.list?.products?.length > 4;
  const userFullName = getUserFullname(fishingReport.user);

  const userHref = fishingReport.user.slug
    ? `${OMNIA_URL}${OmniaRoutePaths.USERS}/${fishingReport.user.slug}`
    : null;
  const userContent = userHref ? <a href={userHref}>{userFullName}</a> : userFullName;
  const hasFeaturedProduct = fishingReport.featured_product != null;
  const listProducts = fishingReport?.list?.products || [];
  const listProductsWithFeatured = [fishingReport.featured_product, ...listProducts].filter(
    Boolean
  );

  return (
    <div className={styles.fishingReport}>
      <div className={styles.top}>
        <div className={styles.top_col1}>
          <a href={getWaterbodyUrl(fishingReport.waterbody)}>
            <span className={styles.waterbodyName}>
              {fishingReport.waterbody.primary_name}
              {fishingReport.waterbody.locales.length > 0 && ', '}
              {fishingReport.waterbody.locales.map((locale) => `${locale.state}`).join(', ')}
            </span>
          </a>
        </div>

        <div className={styles.top_col2}>
          <div className={styles.details}>
            <p className={styles.detail}>
              Season:
              <br />
              <strong>{fishingReport.season.display_name}</strong>
            </p>
            <p className={styles.detail}>
              Species:
              <br />
              <strong>{fishingReport.species.display_name}</strong>
            </p>
            <p className={styles.detail}>
              Structure:
              <br />
              <strong>{fishingReport.structure.display_name}</strong>
            </p>
            <p className={styles.detail}>
              Style:
              <br />
              <strong>{fishingReport.style.display_name}</strong>
            </p>
          </div>
        </div>
      </div>

      <div className={styles.bottom}>
        <div className={styles.bottom_col1}>
          <a href={getWaterbodyUrl(fishingReport.waterbody)} className={styles.waterbody}>
            <WaterbodyImg
              waterbody={fishingReport.waterbody}
              size={{ width: 200, height: 100 }}
              className={styles.waterbodyImg}
            />
          </a>

          {fishingReport.comments != null && (
            <div className={styles.commentsWrapper}>
              <q>{comments}</q>{' '}
              {commentsTooLong ? (
                <span
                  onClick={() => {
                    setShowFullComments(true);
                  }}
                  className={base.link}
                >
                  Read More
                </span>
              ) : null}
            </div>
          )}

          <div className={styles.fishingReportUser}>
            <Avatar
              image={getImgixPath(fishingReport.user.image, { w: 100 })}
              className={styles.avatar}
              href={userHref}
            />
            <p className={styles.reportedBy}>
              Reported by {userContent || 'Omnia Community'} <br />
              on{' '}
              {new Date(fishingReport.created_at).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })}
            </p>
          </div>
        </div>

        <div className={styles.bottom_col2}>
          {listProductsWithFeatured.length > 0 && (
            <div className={styles.products}>
              <p className={styles.productsHeading}>Products Associated With This Report</p>
              <section className={styles.carouselWrapper}>
                <OmniaCarousel
                  options={{
                    prevNextButtons: shouldShowArrows,
                  }}
                  reloadOnUpdate={true}
                  static={true}
                  flickityRef={flickityRef}
                >
                  {listProductsWithFeatured.map((product, i) => {
                    const isFeatured = hasFeaturedProduct && i === 0;
                    return (
                      <div
                        className={classNames(styles.product, styles.product)}
                        key={`${product.sku}${isFeatured}`}
                      >
                        <a
                          href={`${OMNIA_URL}${OmniaRoutePaths.PRODUCTS}/${encodeVariantUrl(
                            product.shopify_product_handle,
                            product.shopify_options
                          )}`}
                          className={styles.productLink}
                        >
                          <img
                            src={productPlaceholder}
                            data-flickity-lazyload={setShopifyImgWidth(product.img_url, 300)}
                            alt={product.title}
                            className={styles.productImg}
                          />

                          <p className={styles.title}>{product.shopify_product_title}</p>
                          {isFeatured && <SvgFire className={styles.fire} />}
                        </a>
                      </div>
                    );
                  })}
                </OmniaCarousel>
              </section>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
