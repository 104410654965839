import React from 'react';
import { getImgixPath } from '../../lib/imgix';
import { getUserFullname, getUserUrl } from '../../lib/user_profile';
import { PrismicArticle } from '../../models/prismic_article';
import { Avatar } from '../avatar/avatar';
import * as styles from './article_ambassador.module.less';

interface ArticleAmbassadorProps {
  article: PrismicArticle;
}

export default class ArticleAmbassador extends React.Component<ArticleAmbassadorProps, {}> {
  render() {
    const { article } = this.props;
    if (!article.omnia_data || !article.omnia_data.ambassador) {
      return null;
    }
    const { ambassador } = article.omnia_data;

    const name = getUserFullname(ambassador.user);
    return (
      <div className={styles.container}>
        <h2>Article Ambassador</h2>
        <a href={getUserUrl(ambassador)} className={styles.ambassador}>
          <Avatar image={getImgixPath(ambassador.image, { w: 60 })} />
          <span>{name}</span>
        </a>
      </div>
    );
  }
}
