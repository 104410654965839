export interface SelectedOption {
  name: string;
  value: string;
}

export interface Product {
  annotations?: {
    clarities: string[];
    species: string[];
    styles: string[];
    waterbodies: string[];
  };
  brand: string;
  category_name: string;
  hottable: boolean;
  id: number;
  img_url: string;
  inventory: number;
  mfr_item_number: string;
  price: string;
  shopify_graphql_id: string;
  shopify_options: SelectedOption[];
  shopify_product_handle: string;
  shopify_product_id: string;
  shopify_product_title: string;
  sku: string;
  species: string[];
  styles: string[];
  subcat_type_name: string;
  subcategory_name: string;
  tags: string[];
  title: string;
}

export interface ProductGrouped extends Product {
  groupBy: SelectedOption;
}

export enum ProductTags {
  NEW_COLORS = 'new_colors',
}

export function mockProduct(overrides: Partial<Product> = {}): Product {
  return {
    brand: 'mock brand',
    category_name: 'mock category_name',
    hottable: true,
    id: 193825718923457,
    img_url: 'http://example.com/img.jpg',
    inventory: 1,
    mfr_item_number: '1481902841092841287',
    price: '0.99',
    shopify_graphql_id: 'mock shopify_graphql_id',
    shopify_product_id: '54103845190841290',
    shopify_product_handle: 'mock_shopify_handle',
    shopify_product_title: 'mock_shopify_title',
    shopify_options: [
      {
        name: 'SIZE',
        value: '3-1/2"',
      },
      {
        name: 'COLOR',
        value: 'PINK TIGER UV',
      },
    ],
    sku: 'mock sku',
    species: [],
    styles: [],
    subcat_type_name: 'mock subcat_type_name',
    subcategory_name: 'mock subcategory_name',
    tags: [],
    title: 'mock title',
    ...overrides,
  };
}
