import classNames from 'classnames';
import React from 'react';
import * as styles from './avatar.module.less';
import iconSvg from './user.svg';

export const Avatar: React.FC<{ image?: string; className?: string; href?: string }> = (props) => {
  const { image, className, href } = props;
  const hasImage = image != null;
  let icon = hasImage ? image : iconSvg;
  const img = (
    <img
      src={icon}
      alt=""
      className={classNames(styles.avatarImage, {
        [styles.avatarImage__default]: !hasImage,
      })}
    />
  );

  return (
    <div className={classNames(styles.avatar, className)}>
      {href ? <a href={href}>{img}</a> : img}
    </div>
  );
};
