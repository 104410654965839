import React from 'react';
import { fitBounds } from '../../lib/fit_bounds';
import { Waterbody } from '../../models/waterbody';

interface WaterbodyImgProps {
  waterbody: Pick<Waterbody, 'bbox' | 'lat' | 'lng' | 'primary_name'>;
  size?: {
    width: number;
    height: number;
  };
  className?: string;
}

export const WaterbodyImg: React.FC<WaterbodyImgProps> = (props) => {
  const { waterbody, size, className } = props;

  const imgSize = {
    width: 400,
    height: 300,
    ...size,
  };

  const zoom = waterbody.bbox
    ? fitBounds([waterbody.bbox[0], waterbody.bbox[2]], {
        width: imgSize.width,
        height: imgSize.height,
      }).zoom
    : 12;

  return (
    <img
      src={`https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/static/pin-s+042930(${waterbody.lng},${waterbody.lat})/${waterbody.lng},${waterbody.lat},${zoom},0.00,0.00/${imgSize.width}x${imgSize.height}?access_token=${process.env.GATSBY_MAPBOX_ACCESS_TOKEN}`}
      alt={`${waterbody.primary_name} Map`}
      className={className}
    />
  );
};
