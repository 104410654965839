import classNames from 'classnames';
import React from 'react';
import * as styles from './anchor_button.module.less';

export const AnchorButton: React.FC<{ href: string; className?: string; rel?: string }> = (
  props
) => {
  const { children, href, rel, className } = props;

  return (
    <a href={href} className={classNames(styles.link, className)} rel={rel}>
      {children}
    </a>
  );
};
