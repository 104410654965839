// extracted by mini-css-extract-plugin
export var container = "article_sidebar-module--container--128AI";
export var heading = "article_sidebar-module--heading--KjnvD";
export var sectionHeading = "article_sidebar-module--sectionHeading--2P4zj";
export var linkList = "article_sidebar-module--linkList--3uRmD";
export var listItem = "article_sidebar-module--listItem--L3dt-";
export var linkPill = "article_sidebar-module--linkPill--xXEa1";
export var waterbodiesList = "article_sidebar-module--waterbodiesList--2KrHf";
export var waterbodiesItem = "article_sidebar-module--waterbodiesItem--1oGuy";
export var waterbodiesLink = "article_sidebar-module--waterbodiesLink--38JiU";
export var waterbodiesImg = "article_sidebar-module--waterbodiesImg--pZDiB";