import classNames from 'classnames';
import React from 'react';
import { getProductUrl } from '../../lib/product';
import { setShopifyImgWidth } from '../../lib/set_shopify_img_width';
import { Product, ProductTags } from '../../models/product';
import * as styles from './product_detail_grid.module.less';

interface RecommendedProductRowProps {
  product: Product;
}

const RecommendedProductRow: React.FC<RecommendedProductRowProps> = (props) => {
  const { product } = props;
  const isNew = product.tags.indexOf(ProductTags.NEW_COLORS) > -1;
  const hasColor = props.product.shopify_options.some(
    (option) => option.name.toLowerCase() === 'color'
  );

  return (
    <div className={classNames(styles.row)}>
      <div className={styles.cell} style={{ position: 'relative' }}>
        {hasColor && <i className={styles.cellHeader}>Color</i>}
        <div className={styles.cellContent}>
          <span>
            {hasColor &&
              product.shopify_options.filter((option) => option.name.toLowerCase() === 'color')[0]
                .value}
            {isNew && <span className={classNames(styles.newColorTag)}>NEW</span>}
          </span>
          <div className={styles.imgWrapper}>
            <a href={getProductUrl(product)}>
              <img
                src={setShopifyImgWidth(product.img_url, 300)}
                width="100"
                alt=""
                className={styles.img}
              />
            </a>
          </div>
        </div>
      </div>
      {product.shopify_options
        .filter((option) => option.name.toLowerCase() !== 'color')
        .map((option) => {
          return (
            <div className={styles.cell} key={option.name}>
              <i className={styles.cellHeader}>{option.name}</i>
              <div className={styles.cellContent}>
                <a className={styles.textLink} href={getProductUrl(product)}>
                  {option.value}
                </a>
              </div>
            </div>
          );
        })}
      <div className={styles.cell}>
        <i className={styles.cellHeader}>Price</i>
        <div className={styles.cellContent}>
          <a className={styles.textLink} href={getProductUrl(product)}>
            ${product.price}
          </a>
        </div>
      </div>
      <div className={classNames(styles.cell)}>
        <div className={styles.cellContent}>
          <div className={styles.actions}>
            <a href={getProductUrl(product)}>Buy now</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecommendedProductRow;
