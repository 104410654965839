import { WebMercatorViewport } from '@math.gl/web-mercator';
import { WebMercatorViewportOptions } from '@math.gl/web-mercator/src/web-mercator-viewport';

// bounds = opposite corners specified as [[lon, lat], [lon, lat]]
export function fitBounds(
  bounds: [[number, number], [number, number]],
  viewport: WebMercatorViewportOptions,
  padding = 60
) {
  return new WebMercatorViewport(viewport).fitBounds(bounds, { padding });
}
