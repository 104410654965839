// extracted by mini-css-extract-plugin
export var wrapper = "article-module--wrapper--3_lj1";
export var article = "article-module--article--1cKNE";
export var header = "article-module--header--2ctE2";
export var h1 = "article-module--h1--3kr1q";
export var publicationDate = "article-module--publicationDate--1FJOZ";
export var body = "article-module--body--19JRU";
export var articleText = "article-module--articleText--3QOSF";
export var sidebar = "article-module--sidebar--hR23o";
export var productFamily = "article-module--productFamily--knBQR";
export var relatedArticlesSection = "article-module--relatedArticlesSection--5c6KW";
export var relatedArticles = "article-module--relatedArticles--U4Yb7";
export var relatedArticle = "article-module--relatedArticle--2_gP-";