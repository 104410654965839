import _ from 'lodash';
import { SelectedOption } from '../models/product';
import { OMNIA_URL, OmniaRoutePaths } from './routes';

// ========================================================================== //
// Variant URLs
// ========================================================================== //

// !!!
// This must match omnia's implementation
// !!!

export const OPTION_DIVIDER = '--';
export const VARIANT_KEY_VALUE_DIVIDER = '*';
export const WHITESPACE_REPLACEMENT = '~';
export const SLASH_REPLACEMENT = '__';

export function encodeVariantUrl(productHandle: string, selectedOptions: SelectedOption[]) {
  const options = _.orderBy(selectedOptions, 'name').map(
    option =>
      `${option.name.toLowerCase()}${VARIANT_KEY_VALUE_DIVIDER}${option.value
        .toLowerCase()
        .replace(/\s/g, WHITESPACE_REPLACEMENT)
        .replace(/\//g, SLASH_REPLACEMENT)}`
  );
  const optionsEncoded = encodeURIComponent(options.join(OPTION_DIVIDER));
  return `${OMNIA_URL}${OmniaRoutePaths.PRODUCTS}/${productHandle}/${optionsEncoded}`;
}
