// extracted by mini-css-extract-plugin
export var list = "list-module--list--1N7P4";
export var list__expanded = "list-module--list__expanded--1QKHs";
export var header = "list-module--header--1TGkV";
export var img = "list-module--img--2PtYL";
export var text = "list-module--text--3GPHT";
export var heading = "list-module--heading--30bv9";
export var type = "list-module--type--MH4fm";
export var buttons = "list-module--buttons--vuTWQ";
export var button = "list-module--button--jLhRb";
export var expandButton = "list-module--expandButton--vbHRX";
export var description = "list-module--description--1Swm7";
export var linkPill = "list-module--linkPill--FL6kW";
export var productsWrapper = "list-module--productsWrapper--klW4e";