// extracted by mini-css-extract-plugin
export var table = "product_detail_grid-module--table--1fFRK";
export var thead = "product_detail_grid-module--thead--3feDy";
export var cell = "product_detail_grid-module--cell--35I2r";
export var row = "product_detail_grid-module--row--f6HGq";
export var hidden = "product_detail_grid-module--hidden--3qb3M";
export var hasReject = "product_detail_grid-module--hasReject--2Uj7B";
export var cellContent = "product_detail_grid-module--cellContent--10YvP";
export var cellHeader = "product_detail_grid-module--cellHeader--3J6hr";
export var imgWrapper = "product_detail_grid-module--imgWrapper--rN6JV";
export var img = "product_detail_grid-module--img--3w78h";
export var actions = "product_detail_grid-module--actions--KJh1c";
export var quantityInput = "product_detail_grid-module--quantityInput--xcv5V";
export var fire = "product_detail_grid-module--fire--1bL8m";
export var hot = "product_detail_grid-module--hot--1H45R";
export var outOfStock = "product_detail_grid-module--outOfStock--_UXN-";
export var newColorTag = "product_detail_grid-module--newColorTag--qmkLf";
export var textLink = "product_detail_grid-module--textLink--38FXK";