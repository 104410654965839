export function getUserFullname(user: { first_name: string; last_name: string }) {
  if (!user) {
    return '';
  }

  const { first_name, last_name } = user;
  const first = first_name && first_name.trim();
  const last = last_name && last_name.trim();
  if (first_name && last_name) {
    return `${first} ${last}`;
  }
  if (first_name && !last_name) {
    return first;
  }
  if (!first_name && last_name) {
    return last;
  }

  return '';
}
