import classNames from 'classnames';
import queryString from 'query-string';
import React from 'react';
import AnimateHeight from 'react-animate-height';
import ArrowDownIcon from '../../images/arrow-down.inline.svg';
import ArrowUpIcon from '../../images/arrow-up.inline.svg';
import { getImgixPath } from '../../lib/imgix';
import { OmniaRoutePaths, OMNIA_URL } from '../../lib/routes';
import { setShopifyImgWidth } from '../../lib/set_shopify_img_width';
import { Brand } from '../../models/brand';
import { Product, ProductTags } from '../../models/product';
import { ProductFamily, ProductFamilyTags } from '../../models/product_family';
import { Button } from '../button/button';
import ProductDetailGrid from '../product_detail_grid/product_detail_grid';
import * as styles from './product_family_card.module.less';

export interface ProductFamilyCardProps {
  brand: Brand;
  productFamily: ProductFamily;
  products: Product[];
  utm?: Record<string, string>;
  expanded?: boolean;
  className?: string;
}

export const ProductFamilyCard = (props: ProductFamilyCardProps) => {
  const { products, productFamily, brand, utm, expanded, className } = props;

  const [open, setOpen] = React.useState(!!expanded);

  const height = open ? 'auto' : 0;

  function getColorOption() {
    const colorOption = productFamily.options.filter(
      (option) => option.name.toLowerCase() === 'color'
    )[0];
    if (!colorOption || colorOption.values.length <= 1) {
      return null;
    }

    const newColorProducts = products.filter(
      (product) => product.tags.indexOf(ProductTags.NEW_COLORS) > -1
    );
    const newColors = newColorProducts
      .map((product) => {
        const color = product.shopify_options.filter(
          (option) => option.name.toLowerCase() === 'color'
        )[0];
        if (color) {
          return color.value;
        }
        return null;
      })
      .filter(Boolean);

    return (
      <div>
        <p className={styles.optionTitle}>Colors:</p>
        <div key={colorOption.name} className={styles.colors}>
          {colorOption.values.map((value) => {
            const isNewColor = newColors.indexOf(value.value) > -1;
            return (
              <div className={styles.color} key={value.value}>
                <a href={omniaProductUrl}>
                  <img src={setShopifyImgWidth(value.src, 300)} alt={value.value} width="100" />
                </a>
                {isNewColor && <span className={styles.newColorTag}>NEW</span>}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  function getNonColorOptions() {
    const nonColorOptions = props.productFamily.options.filter(
      (option) => option.name.toLowerCase() !== 'color'
    );
    return nonColorOptions.map((option) => (
      <div key={option.name}>
        <p className={styles.optionTitle}>{option.name}:</p>
        {option.values.map((value) => {
          const recommendedClass = value.recommended ? styles.recommended : null;
          return (
            <a
              href={omniaProductUrl}
              className={classNames(styles.optionText, recommendedClass)}
              key={value.value}
            >
              {value.value}
            </a>
          );
        })}
      </div>
    ));
  }

  function getPriceRange() {
    if (productFamily.price_min === productFamily.price_max) {
      return productFamily.price_min;
    }
    return `${productFamily.price_min} – ${productFamily.price_max}`;
  }

  const isNewRelease = productFamily.tags.indexOf(ProductFamilyTags.NEW_RELEASES) > -1;

  let omniaProductUrl = `${OMNIA_URL}${OmniaRoutePaths.PRODUCTS}/${productFamily.handle}`;
  if (utm) {
    omniaProductUrl = omniaProductUrl + '?' + queryString.stringify(utm);
  }

  return (
    <div className={className}>
      <div className={classNames(styles.family)}>
        <div className={styles.overview}>
          <div className={styles.img}>
            <a href={omniaProductUrl}>
              <img src={setShopifyImgWidth(productFamily.img_url, 300)} alt={productFamily.title} />
            </a>
          </div>
          <div className={styles.options}>
            <div>
              <a href={omniaProductUrl} className={styles.title}>
                {productFamily.title}
                {isNewRelease && <span className={styles.newReleaseTag}>NEW RELEASE</span>}
              </a>
            </div>
            {getColorOption()}
            {getNonColorOptions()}
          </div>
          <div className={styles.details}>
            {brand && brand.image && (
              <img
                src={getImgixPath(brand.image, { w: 300 })}
                alt={brand.display_name}
                className={styles.brandImg}
              />
            )}
            <a href={omniaProductUrl} className={styles.price}>
              ${getPriceRange()}
            </a>
            <a href={omniaProductUrl} className={styles.detailsButton}>
              Product Details
            </a>
          </div>
        </div>

        <Button
          onClick={() => {
            setOpen(!open);
          }}
          className={styles.seeAllButton}
          type="text"
        >
          {open ? (
            <>
              <span className={styles.seeAllButton__icon}>
                <ArrowUpIcon />
              </span>
              Hide All Options
            </>
          ) : (
            <>
              <span className={styles.seeAllButton__icon}>
                <ArrowDownIcon />
              </span>
              View All Options
            </>
          )}
        </Button>

        <AnimateHeight duration={products.length * 50} height={height} easing="ease-out">
          <ProductDetailGrid productFamily={productFamily} products={products} />
        </AnimateHeight>
      </div>
    </div>
  );
};
