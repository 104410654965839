import React from 'react';
import Loadable from 'react-loadable';
import { FlickityOptions } from '../../flickity';
import * as styles from './omnia_carousel.module.less';

// see this for an SSR alternative if this is not ideal for whatever reason
// https://github.com/artsy/reaction/blob/8054f1b1543d5d2cda3270db91fec1bdad5bfb8d/src/Components/v2/Carousel.tsx
const LoadableFlickity = Loadable({
  loader: () => import(/* webpackChunkName: "FlickityComponent" */ './flickity_component'),
  loading: (): null => null,
});

const initialState = {
  mounted: false,
};

interface Props {
  className: string;
  options: FlickityOptions;
  static: boolean;
  reloadOnUpdate: boolean;
  onStaticClick: (
    event: PointerEvent,
    pointer: PointerEvent,
    cellElement: any,
    cellIndex: number
  ) => void;
  disableImagesLoaded: boolean;
  elementType: string;
  flickityRef: (ref: HTMLDivElement) => void;
}

export default class OmniaCarousel extends React.Component<Props, Readonly<typeof initialState>> {
  state = initialState;

  static defaultProps = {
    className: '',
    disableImagesLoaded: false,
    elementType: 'div',
    flickityRef: (el) => null,
    options: {},
    reloadOnUpdate: false,
    static: false,
  } as Props;

  componentDidMount() {
    this.setState({ mounted: true });
  }

  render() {
    if (!this.state.mounted) {
      return <div className={styles.container}>{this.props.children}</div>;
    }

    const options = {
      pageDots: false,
      contain: true,
      groupCells: true,
      cellAlign: 'left',
      lazyLoad: true,
      ...this.props.options,
    };

    return (
      <LoadableFlickity {...this.props} options={options}>
        {this.props.children}
      </LoadableFlickity>
    );
  }
}
