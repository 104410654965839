import classNames from 'classnames';
import queryString from 'query-string';
import React from 'react';
import { getProductUrl } from '../../lib/product';
import { setShopifyImgWidth } from '../../lib/set_shopify_img_width';
import { toDollars } from '../../lib/to_dollars';
import { Product } from '../../models/product';
import { AnchorButton } from '../anchor_button/anchor_button';
import * as styles from './carousel_product_card.module.less';
import productPlaceholder from './product-placeholder.svg';

interface Props {
  product: Product;
  className?: string;
  utm?: Record<string, string>;
}

export const NAME_OWN = 'own';
export const NAME_ADD_CART = 'add_cart';

export const CarouselProductCard: React.FC<Props> = (props) => {
  const { product, className, utm } = props;
  let productUrl = getProductUrl(product);
  if (utm) {
    productUrl = productUrl + '?' + queryString.stringify(utm);
  }

  return (
    <div className={classNames(styles.product, className)}>
      <img
        src={productPlaceholder}
        data-flickity-lazyload={setShopifyImgWidth(product.img_url, 300)}
        alt={product.title}
        className={styles.productImg}
      />

      <p className={styles.title}>
        <a href={productUrl}>{product.shopify_product_title}</a>
      </p>

      {product.shopify_options.map((option) => (
        <p key={option.name} className={styles.attribute}>
          {option.name}: <strong>{option.value}</strong>
        </p>
      ))}

      <p className={styles.price}>{toDollars(product.price)}</p>

      <AnchorButton href={productUrl}>Buy Now</AnchorButton>
    </div>
  );
};
