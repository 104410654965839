import _ from 'lodash';
import queryString from 'query-string';

export const BASSU_IMGIX_URL = 'images.prismic.io/bassu/';
export const OMNIA_IMGIX_URL = 'omnia-fishing.imgix.net/';

// from https://docs.imgix.com/apis/url
interface ImgixFormatting {
  [key: string]: unknown; // to "fix" a TS3.5.1 error
  w?: number;
  h?: number;
  auto?: 'format';
  q?: number;
}

export const getImgixPath = (path: string, options: ImgixFormatting = {}) => {
  if (
    !path ||
    (path.indexOf(BASSU_IMGIX_URL) === -1 && path.indexOf(OMNIA_IMGIX_URL) === -1) ||
    _.isEmpty(options)
  ) {
    return path;
  }

  const basePath = path.split('?')[0];
  const query = path.split('?')[1];

  const existingParams = queryString.parse(query);
  const newParams = existingParams
    ? {
        ...existingParams,
        ...options,
      }
    : options;

  const queryParams = queryString.stringify(newParams);

  return `${basePath}?${queryParams}`;
};
