import classNames from 'classnames';
import React from 'react';
import * as styles from './button.module.less';

interface ButtonProps {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
  type: 'primary' | 'utility' | 'text';
}

export const Button = (props: ButtonProps) => {
  const { children, className, onClick, type } = props;
  return (
    <button
      className={classNames(styles.button, className, {
        [styles.primary]: type === 'primary',
        [styles.utility]: type === 'utility',
        [styles.text]: type === 'text',
      })}
      onClick={onClick}
    >
      <>{children}</>
    </button>
  );
};
