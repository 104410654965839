// extracted by mini-css-extract-plugin
export var family = "product_family_card-module--family--3rDG1";
export var overview = "product_family_card-module--overview--19Ypr";
export var img = "product_family_card-module--img--1sw53";
export var options = "product_family_card-module--options--3TH8D";
export var details = "product_family_card-module--details--1RGxZ";
export var colors = "product_family_card-module--colors--1FC0j";
export var color = "product_family_card-module--color--3I9TP";
export var optionTitle = "product_family_card-module--optionTitle--_84A9";
export var optionText = "product_family_card-module--optionText--2b_nW";
export var title = "product_family_card-module--title--2nenz";
export var price = "product_family_card-module--price--TF6AK";
export var detailsButton = "product_family_card-module--detailsButton--1DjBp";
export var brandImg = "product_family_card-module--brandImg--22JUB";
export var recommended = "product_family_card-module--recommended--2PBfI";
export var fire = "product_family_card-module--fire--3qdwx";
export var newColorTag = "product_family_card-module--newColorTag--3klWg";
export var newReleaseTag = "product_family_card-module--newReleaseTag--1Wxjq";
export var seeAllButton = "product_family_card-module--seeAllButton--2Ktxg";
export var seeAllButton__icon = "product_family_card-module--seeAllButton__icon--3kRI8";