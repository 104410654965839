import { Link } from 'gatsby';
import queryString from 'query-string';
import React from 'react';
import { filterTagsByPrefix, PrismicTagPrefixes } from '../../lib/prismic_tags';
import { OmniaRoutePaths, OMNIA_URL, RoutePaths } from '../../lib/routes';
import { PrismicArticle } from '../../models/prismic_article';
import { Species } from '../../models/species';
import { Style } from '../../models/style';
import { WaterbodyImg } from '../waterbody_img/waterbody_img';
import * as cssStyles from './article_sidebar.module.less';

interface ArticleSidebarProps {
  article: PrismicArticle;
  species: Species[];
  styles: Style[];
  utm?: Record<string, string>;
}

const ArticleSidebar = (props: ArticleSidebarProps) => {
  const { article, utm, styles, species } = props;
  const articleStyles = filterTagsByPrefix(article.tags, PrismicTagPrefixes.STYLE);
  const fullStyles = styles.filter((style) => articleStyles.indexOf(style.name) > -1);
  const hasStyles = fullStyles.length > 0;

  const articleSpecies = filterTagsByPrefix(article.tags, PrismicTagPrefixes.SPECIES);
  const fullSpecies = species.filter((specie) => articleSpecies.indexOf(specie.name) > -1);
  const hasSpecies = fullStyles.length > 0;

  const hasStates = article.omnia_data.states?.length > 0;
  const hasWaterbodies = article.omnia_data.waterbodies?.length > 0;

  const utmSidebar = {
    ...utm,
    utm_term: 'article_sidebar',
  };

  const utmSidebarString = queryString.stringify(utmSidebar);

  const empty = !hasSpecies && !hasStyles && !hasWaterbodies && !hasStates;
  if (!article || empty) {
    return null;
  }

  return (
    <div className={cssStyles.container}>
      <h4 className={cssStyles.heading}>Mentioned in this article</h4>
      {hasSpecies && (
        <>
          <h5 className={cssStyles.sectionHeading}>Species:</h5>
          <p>
            {fullSpecies.map((specie) => (
              <a
                href={`${OMNIA_URL}${OmniaRoutePaths.SPECIES}/${specie.url_path}?${utmSidebarString}`}
                className={cssStyles.linkPill}
                key={specie.name}
              >
                {specie.display_name}
              </a>
            ))}
          </p>
        </>
      )}
      {hasStyles && (
        <>
          <h5 className={cssStyles.sectionHeading}>Techniques:</h5>
          <p>
            {fullStyles.map((style) => (
              <React.Fragment key={style.name}>
                <span className={cssStyles.linkPill}>
                  {style.display_name} [
                  <Link to={`${RoutePaths.TECHNIQUES}${style.url_path}`}>More Content</Link> |{' '}
                  <a href={`${OMNIA_URL}${OmniaRoutePaths.STYLES}/${style.url_path}`}>Shop</a>]
                </span>
              </React.Fragment>
            ))}
          </p>
        </>
      )}
      {hasStates && (
        <>
          <h5 className={cssStyles.sectionHeading}>States:</h5>
          <p>
            {article.omnia_data.states.map((state) => (
              <a
                href={`${OMNIA_URL}${OmniaRoutePaths.STATES}/${state.slug}?${utmSidebarString}`}
                className={cssStyles.linkPill}
                key={state.abbr}
              >
                {state.name}
              </a>
            ))}
          </p>
        </>
      )}
      {hasWaterbodies && (
        <>
          <h5 className={cssStyles.sectionHeading}>Waterbodies:</h5>
          <ul className={cssStyles.waterbodiesList}>
            {article.omnia_data.waterbodies.map((waterbody) => {
              return (
                <li className={cssStyles.waterbodiesItem} key={waterbody.waterbody_id}>
                  <a
                    href={`${OMNIA_URL}${OmniaRoutePaths.WATERS}/${
                      waterbody.url_slug
                    }?${queryString.stringify({
                      ...utm,
                      utm_term: 'waterbody_sidebar',
                    })}`}
                    className={cssStyles.waterbodiesLink}
                  >
                    <WaterbodyImg
                      waterbody={waterbody}
                      size={{ width: 400, height: 300 }}
                      className={cssStyles.waterbodiesImg}
                    />
                    {waterbody.primary_name}
                  </a>
                </li>
              );
            })}
          </ul>
        </>
      )}
    </div>
  );
};

export default ArticleSidebar;
