import { Product } from './product';

export interface ProductFamily {
  brand: string;
  description_data: string;
  handle: string;
  img_url: string;
  images: ProductFamilyImage[];
  meta_description: string;
  options: ProductFamilyOption[];
  price_max: number;
  price_min: number;
  products: Product[];
  related_product_families: ProductFamily[];
  rotate_images: string[];
  complimentary_product_families: ProductFamily[];
  subcategory: string;
  tags: string[];
  title: string;
  url_360: string;
  video_embed: string;
}

export interface ProductFamilyImage {
  image: string;
  alt_text: string;
  display_order: number;
}

export interface ProductFamilyOption {
  name: string;
  values: Array<{
    value: string;
    src?: string;
    recommended?: boolean;
  }>;
}

export function mockProductFamily(overrides: Partial<ProductFamily> = {}): ProductFamily {
  return {
    brand: 'mock brand',
    description_data: '<strong>extra data!</strong>',
    handle: 'mock-handle',
    images: [],
    img_url: 'http://example.com/jpg.jpg',
    meta_description: 'meta description',
    options: [mockProductFamilyOption()],
    price_max: 2.99,
    price_min: 1.99,
    products: [],
    related_product_families: [],
    rotate_images: [],
    complimentary_product_families: [],
    subcategory: 'mocks-fakes',
    tags: [],
    title: 'mock title',
    url_360: null,
    video_embed: null,
    ...overrides,
  };
}

export enum ProductFamilyTags {
  NEW_RELEASES = 'new_releases',
}

export function mockProductFamilyOption(
  overrides: Partial<ProductFamilyOption> = {}
): ProductFamilyOption {
  return {
    name: 'WEIGHT',
    values: [{ value: '1/2 oz' }, { value: '1/4 oz' }],
    ...overrides,
  };
}

export function mockProductFamilyImage(
  overrides: Partial<ProductFamilyImage> = {}
): ProductFamilyImage {
  return {
    image: 'http://example/com/jpg.gif',
    alt_text: 'alt alt',
    display_order: null,
    ...overrides,
  };
}
