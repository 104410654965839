// extracted by mini-css-extract-plugin
export var fishingReport = "fishing_report_card-module--fishingReport--2vEJ8";
export var top = "fishing_report_card-module--top--1MbH1";
export var top_col1 = "fishing_report_card-module--top_col1--3OnxA";
export var top_col2 = "fishing_report_card-module--top_col2--wtMye";
export var bottom = "fishing_report_card-module--bottom--2c9EE";
export var bottom_col1 = "fishing_report_card-module--bottom_col1--3ebd4";
export var bottom_col2 = "fishing_report_card-module--bottom_col2--2c6Om";
export var details = "fishing_report_card-module--details--VJc7d";
export var detail = "fishing_report_card-module--detail--1g5bV";
export var commentsWrapper = "fishing_report_card-module--commentsWrapper--1nJV7";
export var fishingReportUser = "fishing_report_card-module--fishingReportUser--2IxPA";
export var avatar = "fishing_report_card-module--avatar--3GBt7";
export var reportedBy = "fishing_report_card-module--reportedBy--2Wel2";
export var products = "fishing_report_card-module--products--2uBRN";
export var productsHeading = "fishing_report_card-module--productsHeading--2-emj";
export var carouselWrapper = "fishing_report_card-module--carouselWrapper--246Gi";
export var product = "fishing_report_card-module--product--TpbKo";
export var productImg = "fishing_report_card-module--productImg--Ov-JQ";
export var title = "fishing_report_card-module--title--3_VnJ";
export var productLink = "fishing_report_card-module--productLink--3oH8b";
export var fire = "fishing_report_card-module--fire--1FR_b";
export var waterbody = "fishing_report_card-module--waterbody--10HJW";
export var waterbodyImg = "fishing_report_card-module--waterbodyImg--3whxh";
export var waterbodyName = "fishing_report_card-module--waterbodyName--1yZvU";