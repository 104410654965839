import classNames from 'classnames';
import React from 'react';
import { useIsMobile } from '../../hooks/useIsMobile';
import { getImgixPath } from '../../lib/imgix';
import { getUserFullname } from '../../lib/user_profile';
import { List } from '../../models/list';
import OmniaCarousel from '../carousel/omnia_carousel';
import { CarouselProductCard } from '../carousel_product_card/carousel_product_card';
import * as styles from './list.module.less';

interface ListProps {
  list: List;
  expanded?: boolean;
  desktopRowCount?: number;
  productClass?: string;
  utm?: Record<string, string>;
}

export const ListComponent: React.FC<ListProps> = (props) => {
  const { list, productClass, utm } = props;
  let title: string | JSX.Element = list.shop_the_list_title || list.title;
  const isMobile = useIsMobile();

  function getUserLink() {
    const user = list.user;
    if (!user) {
      return null;
    }

    const name = getUserFullname(user);

    return <div style={{ textTransform: 'capitalize', marginBottom: 12 }}>{name}</div>;
  }

  const UserLink = getUserLink();

  const desktopRowCount = props.desktopRowCount || 3;
  const productsInView = isMobile ? 2 : desktopRowCount;
  const shouldShowArrows = isMobile ? true : list.products.length > productsInView;

  return (
    <div className={classNames(styles.list)}>
      <header className={styles.header}>
        <div className={styles.img}>
          <img src={getImgixPath(list.image, { w: 500 })} alt="" />
        </div>
        <div className={styles.text}>
          <p className={styles.type}>LIST</p>
          <h3 className={styles.heading}>
            <strong>{title}</strong>
          </h3>
          {UserLink}
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: list.description }}
          />
        </div>
      </header>

      <section className={styles.productsWrapper}>
        <OmniaCarousel
          options={{
            prevNextButtons: shouldShowArrows,
          }}
          static={true}
        >
          {list.products.map((product) => {
            return (
              <CarouselProductCard
                key={product.sku}
                product={product}
                className={productClass}
                utm={utm}
              />
            );
          })}
        </OmniaCarousel>
      </section>
    </div>
  );
};
