import { Ambassador } from '../models/ambassador';
import { UserProfile } from '../models/user_profile';
import { OmniaRoutePaths, OMNIA_URL } from './routes';

interface Name {
  first_name: string;
  last_name: string;
}

export function getUserFullname(name: Name) {
  if (!name) {
    return '';
  }

  const { first_name, last_name } = name;
  const first = first_name && first_name.trim();
  const last = last_name && last_name.trim();
  if (first_name && last_name) {
    return `${first} ${last}`;
  }
  if (first_name && !last_name) {
    return first;
  }
  if (!first_name && last_name) {
    return last;
  }

  return '';
}

export function getUserUrl(userProfile: UserProfile | Ambassador) {
  return `${OMNIA_URL}${OmniaRoutePaths.USERS}/${userProfile.slug}`;
}
