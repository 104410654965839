import { Link } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import React from 'react';
import { getImgixPath } from '../../lib/imgix';
import { RoutePaths } from '../../lib/routes';
import { PrismicArticle } from '../../models/prismic_article';
import * as styles from './article_contributors.module.less';

interface ArticleAuthorProps {
  article: PrismicArticle;
}

export default class ArticleContributors extends React.Component<ArticleAuthorProps, {}> {
  render() {
    const { article } = this.props;
    const { contributors } = article.data;
    if (
      !contributors ||
      !contributors.length ||
      (contributors.length === 1 && contributors[0].contributor.document == null)
    ) {
      return null;
    }

    return (
      <div className={styles.container}>
        <h2>Contributors</h2>
        {contributors.map((contributor) => {
          const { uid, data } = contributor.contributor.document;
          return (
            <Link to={`${RoutePaths.CONTRIBUTORS}${uid}`} key={uid}>
              <img src={getImgixPath(data.image.url, { w: 400 })} />
              {RichText.asText(data.name.raw)}
            </Link>
          );
        })}
      </div>
    );
  }
}
