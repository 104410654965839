import classNames from 'classnames';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import React from 'react';
import ArticleAmbassador from '../../components/article_ambassador/article_ambassador';
import { ArticleCard } from '../../components/article_card/article_card';
import ArticleContributors from '../../components/article_contributors/article_contributors';
import ArticleSidebar from '../../components/article_sidebar/article_sidebar';
import { ContentWrapper } from '../../components/content_wrapper/content_wrapper';
import { DangerouslySetHtmlContent } from '../../components/dangerously_set_html_content/dangerously_set_html_content';
import { FishingReportCard } from '../../components/fishing_report_card/fishing_report_card';
import { ListComponent } from '../../components/list/list';
import { ProductFamilyCard } from '../../components/product_family_card/product_family_card';
import { SEO } from '../../components/seo';
import Layout from '../../layouts/layout';
import { htmlSerializer } from '../../lib/html_serializer';
import { linkResolver } from '../../lib/link_resolver';
import { PrismicArticle } from '../../models/prismic_article';
import { Species } from '../../models/species';
import { Style } from '../../models/style';
import * as cssStyles from './article.module.less';

// TODO: custom prismic html serializer for gatsby
// https://prismic.io/docs/reactjs/getting-started/prismic-gatsby

export const articleFragment = graphql`
  fragment PrismicArticleFragment on PrismicArticle {
    omnia_data {
      ...OmniaArticleDataFragment
    }

    uid
    tags
    type
    data {
      article_text {
        raw
      }
      article_summary {
        raw
      }
      hero_img {
        alt
        url
        dimensions {
          width
          height
        }
      }
      meta_description
      meta_title
      publication_date
      thumbnail {
        alt
        url
        dimensions {
          width
          height
        }
      }
      title {
        raw
      }
      body {
        ... on PrismicArticleDataBodyText {
          slice_type
          primary {
            text {
              raw
            }
          }
        }
        ... on PrismicArticleDataBodyList {
          slice_type
          primary {
            list {
              id
            }
            list_expanded
          }
        }
        ... on PrismicArticleDataBodyWaterbody {
          slice_type
          primary {
            waterbody {
              id
              activation_requested
              ambassadors_count
              area_sqkm
              average_depth_ft
              classification {
                description
                name
              }
              description
              favorites_count
              fishing_reports_count
              hot_scale
              hotbaits_count
              lat
              lng
              locales {
                counties
                state {
                  abbr
                  image
                  name
                  position
                  slug
                  zone_id
                }
              }
              meta_description
              meta_title
              omnia_clarity
              page_heading
              primary_name
              season_zones {
                end_date
                season
                start_date
              }
              seasons {
                active
                display_name
                id
                name
              }
              secondary_title
              species {
                color
                display_name
                description
                display_order
                id
                image
                meta_description
                meta_title
                name
                page_heading
                recommendations_order
                url_path
              }
              species_known
              url_slug
              waterbody_id
            }
          }
        }
        ... on PrismicArticleDataBodyProductFamily {
          slice_type
          primary {
            product_family {
              id
              available_colors
              brand
              category
              description_data
              handle
              images {
                image
                alt_text
              }
              img_url
              meta_description
              options {
                name
                values {
                  src
                  value
                }
              }
              price_max
              price_min
              products {
                bin_location
                category_name
                brand
                clarities
                compare_at_price
                hidden
                id
                img_url
                inventory
                inventory_tracked
                mfr_item_number
                on_sale
                popularity
                price
                shopify_graphql_id
                shopify_options {
                  name
                  value
                }
                shopify_product_handle
                shopify_product_id
                shopify_product_title
                shopify_variant_id
                sku
                species
                styles
                subcat_type_name
                subcategory_name
                tags
                title
                title_short
                zone_1_fishing_reports_count
                zone_2_fishing_reports_count
                zone_3_fishing_reports_count
              }
              rotate_images
              subcategory
              tags
              title
              title_short
              video_embed
            }
          }
        }
        ... on PrismicArticleDataBodyFishingReport {
          slice_type
          primary {
            fishing_report {
              id
            }
          }
        }
        ... on PrismicArticleDataBodyRawHtml {
          slice_type
          primary {
            html
          }
        }
      }
      partner {
        document {
          ... on PrismicPartner {
            uid
            data {
              name
              logo {
                alt
                dimensions {
                  height
                  width
                }
                url
              }
            }
          }
        }
      }
      contributors {
        contributor {
          document {
            ... on PrismicContributor {
              uid
              data {
                name {
                  raw
                }
                image {
                  url
                  dimensions {
                    height
                    width
                  }
                  alt
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const query = graphql`
  query($uid: String, $styleTags: [String!]) {
    prismicArticle(uid: { eq: $uid }) {
      ...PrismicArticleFragment
    }
    referenceData {
      species {
        name
        display_name
        display_order
        id
        url_path
      }
      styles {
        name
        display_name
        id
        seasons
        species
        subcategories
        url_path
        categories
      }
    }
    related_articles: allPrismicArticle(
      filter: { tags: { in: $styleTags }, uid: { ne: $uid } }
      sort: { fields: data___publication_date, order: DESC }
      limit: 3
    ) {
      nodes {
        ...ArticleCardFragment
      }
    }
  }
`;

interface PageProps {
  location: Location;
}
type WithPageProps<Props> = Props & PageProps;

type ArticlePage = WithPageProps<{ data: any }>;

const ArticlePage = (props: ArticlePage) => {
  const article = props.data.prismicArticle as PrismicArticle;
  if (!article) {
    console.log('prismic page not found');
    return null;
  }
  const { location } = props;

  const species = props.data.referenceData.species as Species[];
  const styles = props.data.referenceData.styles as Style[];
  const relatedArticles = props.data.related_articles.nodes as PrismicArticle[];

  const { omnia_data, data } = article;
  const {
    article_text,
    body,
    json_ld,
    meta_description,
    meta_title,
    thumbnail,
    title,
    publication_date,
  } = data;

  const hasLists = !!(omnia_data.lists != null && omnia_data.lists.length > 0);
  const utm = {
    utm_source: 'bassutopia',
    utm_medium: 'bassutopia.com',
    utm_campaign: article.uid,
  };

  return (
    <Layout>
      <SEO
        title={meta_title || RichText.asText(title.raw)}
        description={meta_description}
        path={location.pathname}
        ogType="article"
        ogImage={thumbnail.url}
      />
      <article className={cssStyles.article}>
        <ContentWrapper>
          <header className={cssStyles.header}>
            <h1 className={classNames(cssStyles.h1)}>{RichText.asText(title.raw)}</h1>
            <p className={cssStyles.publicationDate}>
              {new Intl.DateTimeFormat('en-US', {
                weekday: 'short',
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              }).format(new Date(publication_date))}
            </p>
          </header>

          <div className={cssStyles.body}>
            <div className={cssStyles.articleText}>
              <RichText
                render={article_text.raw}
                htmlSerializer={htmlSerializer}
                linkResolver={linkResolver}
              />

              {body?.map((slice, i) => {
                switch (slice.slice_type) {
                  case 'waterbody': {
                    return null;
                    // return slice.primary.waterbody.primary_name;
                  }
                  case 'list': {
                    return (
                      <ListComponent
                        list={slice.primary.list}
                        expanded={slice.primary.list_expanded === 'expanded'}
                        key={`${slice.slice_type}:${slice.primary.list.id}`}
                        utm={{
                          ...utm,
                          utm_term: 'list_slice',
                        }}
                      />
                    );
                  }
                  case 'product_family': {
                    return (
                      <ProductFamilyCard
                        productFamily={slice.primary.product_family}
                        products={slice.primary.product_family.products}
                        brand={null} // TODO: fix me?
                        key={`${slice.slice_type}:${slice.primary.product_family.handle}`}
                        className={cssStyles.productFamily}
                        utm={{
                          ...utm,
                          utm_term: 'product_slice',
                        }}
                      />
                    );
                  }
                  case 'fishing_report': {
                    return (
                      <FishingReportCard
                        fishingReport={slice.primary.fishing_report}
                        key={slice.primary.fishing_report.slug}
                      />
                    );
                  }
                  case 'text': {
                    return (
                      <React.Fragment
                        key={`${RichText.asText(slice.primary.text.raw)}:${
                          RichText.asText(slice.primary.text.raw).length
                        }`}
                      >
                        <RichText
                          render={slice.primary.text.raw}
                          htmlSerializer={htmlSerializer}
                          linkResolver={linkResolver}
                        />
                      </React.Fragment>
                    );
                  }
                  case 'raw_html': {
                    return (
                      <DangerouslySetHtmlContent
                        html={slice.primary.html}
                        key={`${slice.slice_type}${i}`}
                      />
                    );
                  }
                }
              })}
              {hasLists && (
                <div style={{ marginBottom: 24 }}>
                  <h2>Lists featured in this article</h2>
                  {omnia_data.lists.map((list) => (
                    <ListComponent
                      key={list.id}
                      list={list}
                      expanded={omnia_data.lists.length === 1}
                    />
                  ))}
                </div>
              )}
            </div>

            <aside className={cssStyles.sidebar}>
              <ArticleContributors article={article} />
              <ArticleAmbassador article={article} />
              <ArticleSidebar article={article} species={species} styles={styles} utm={utm} />
            </aside>
          </div>
          {json_ld && (
            <div
              dangerouslySetInnerHTML={{
                __html: RichText.asText(json_ld.raw),
              }}
            />
          )}

          {relatedArticles.length > 0 && (
            <div className={cssStyles.relatedArticlesSection}>
              <h2>Related Articles</h2>
              <ul className={cssStyles.relatedArticles}>
                {relatedArticles.map((article) => (
                  <li className={cssStyles.relatedArticle} key={article.uid}>
                    <ArticleCard article={article} />
                  </li>
                ))}
              </ul>
            </div>
          )}
        </ContentWrapper>
      </article>
    </Layout>
  );
};

export default ArticlePage;
