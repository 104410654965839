import { Elements } from 'prismic-reactjs';
import queryString from 'query-string';
import React from 'react';
import { embeds } from './embeds';
import { getImgixPath } from './imgix';
import { linkResolver } from './link_resolver';
import { hasQueryString, hasUtmTags } from './utm';
const createScript = typeof window !== `undefined` ? require('./embeds').createScript : () => {};

const propsWithUniqueKey = function (props: any, key: any) {
  return Object.assign(props || {}, { key });
};

function serializeStandardTag(tag: string, element: any, children: any, key: string) {
  const props = element.label ? Object.assign({}, { className: element.label }) : {};
  return React.createElement(tag, propsWithUniqueKey(props, key), children);
}

function serializeImage(element: any, key: string) {
  const linkUrl = element.linkTo ? element.linkTo.url || linkResolver(element.linkTo) : null;
  const linkTarget =
    element.linkTo && element.linkTo.target ? { target: element.linkTo.target } : {};
  const linkRel = linkTarget.target ? { rel: 'noopener' } : {};
  const imgSrc = getImgixPath(element.url, { w: 1200 });
  const img = React.createElement('img', { src: imgSrc, alt: element.alt || '' });

  return React.createElement(
    'p',
    propsWithUniqueKey({ className: [element.label || '', 'block-img'].join(' ') }, key),
    linkUrl
      ? React.createElement('a', Object.assign({ href: linkUrl }, linkTarget, linkRel), img)
      : img
  );
}

function serializeEmbed(element: any, key: string) {
  const embed = embeds[element.oembed.provider_name as 'Twitter' | 'Facebook' | 'Instagram'];
  if (embed) {
    createScript(embed);
  }

  const className = `embed embed-${element.oembed.provider_name.toLowerCase()}`;
  const props = Object.assign(
    {
      'data-oembed': element.oembed.embed_url,
      'data-oembed-type': element.oembed.type,
      'data-oembed-provider': element.oembed.provider_name,
      ref: (ref: any) => {
        if (embed) {
          embed.load(ref);
        }
      },
    },
    element.label ? { className: `${className} ${element.label}` } : { className }
  );

  const embedHtml = React.createElement('div', {
    dangerouslySetInnerHTML: { __html: element.oembed.html },
  });

  return React.createElement('div', propsWithUniqueKey(props, key), embedHtml);
}

function serializeLink(element: any, children: any, key: string) {
  const targetAttr = element.data.target ? { target: element.data.target } : {};
  const relAttr = element.data.target ? { rel: 'noopener' } : {};
  let href = element.data.url as string;
  // auto add utm tags to outbound links
  if (href && !hasUtmTags(href)) {
    if (!hasQueryString(href)) {
      href += '?';
    }
    const utm: Record<string, string> = {
      utm_source: 'bassutopia',
      utm_medium: 'bassutopia.com',
    };
    if (typeof window !== 'undefined') {
      utm.utm_campaign = window.location.pathname.split('/').filter(Boolean).pop();
    }
    href += queryString.stringify(utm);
  }
  const props = Object.assign(
    {
      href: href || linkResolver(element.data),
    },
    targetAttr,
    relAttr
  );
  return React.createElement('a', propsWithUniqueKey(props, key), children);
}

function serializeLabel(element: any, children: any, key: string) {
  const props = element.data ? Object.assign({}, { className: element.data.label }) : {};
  return React.createElement('span', propsWithUniqueKey(props, key), children);
}

function serializeSpan(content: any) {
  if (content) {
    return content.split('\n').reduce((acc: any[], p: any) => {
      if (acc.length === 0) {
        return [p];
      } else {
        const brIndex = (acc.length + 1) / 2 - 1;
        const br = React.createElement('br', propsWithUniqueKey({}, brIndex));
        return acc.concat([br, p]);
      }
    }, []);
  } else {
    return null;
  }
}

export const htmlSerializer = function (
  type: any,
  element: any,
  content: any,
  children: any,
  key: any
) {
  switch (type) {
    case Elements.heading1:
      return serializeStandardTag('h1', element, children, key);

    case Elements.heading2:
      return serializeStandardTag('h2', element, children, key);

    case Elements.heading3:
      return serializeStandardTag('h3', element, children, key);

    case Elements.heading4:
      return serializeStandardTag('h4', element, children, key);

    case Elements.heading5:
      return serializeStandardTag('h5', element, children, key);

    case Elements.heading6:
      return serializeStandardTag('h6', element, children, key);

    case Elements.paragraph:
      return serializeStandardTag('p', element, children, key);

    case Elements.preformatted:
      return serializeStandardTag('pre', element, children, key);

    case Elements.strong:
      return serializeStandardTag('strong', element, children, key);

    case Elements.em:
      return serializeStandardTag('em', element, children, key);

    case Elements.listItem:
      return serializeStandardTag('li', element, children, key);

    case Elements.oListItem:
      return serializeStandardTag('li', element, children, key);

    case Elements.list:
      return serializeStandardTag('ul', element, children, key);

    case Elements.oList:
      return serializeStandardTag('ol', element, children, key);

    case Elements.image:
      return serializeImage(element, key);

    case Elements.embed:
      return serializeEmbed(element, key);

    case Elements.hyperlink:
      return serializeLink(element, children, key);

    case Elements.label:
      return serializeLabel(element, children, key);

    case Elements.span:
      return serializeSpan(content);

    default:
      // Always include a default that returns null
      return null;
  }
};
