import React, { useEffect, useRef } from 'react';

// copied from https://github.com/christo-pr/dangerously-set-html-content/blob/c426d8eabfdc17d27eb42c231dcf3af6f4518590/index.d.ts
// it had a react 18 dependency, so installing not possible at the time

interface DangerouslySetHtmlContentProps extends React.HTMLAttributes<HTMLDivElement> {
  html: string;
}

export const DangerouslySetHtmlContent = (props: DangerouslySetHtmlContentProps) => {
  const { html, dangerouslySetInnerHTML, ...rest } = props;
  // We remove 'dangerouslySetInnerHTML' from props passed to the div
  const divRef = useRef(null);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!html || !divRef.current) throw new Error("html prop cant't be null");
    if (!isFirstRender.current) return;
    isFirstRender.current = false;

    const slotHtml = document.createRange().createContextualFragment(html); // Create a 'tiny' document and parse the html string
    divRef.current.innerHTML = ''; // Clear the container
    divRef.current.appendChild(slotHtml); // Append the new content
  }, [html, divRef]);

  return <div {...rest} ref={divRef} />;
};
