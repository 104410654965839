import classNames from 'classnames';
import React from 'react';
import { Product } from '../../models/product';
import { ProductFamily } from '../../models/product_family';
import * as styles from './product_detail_grid.module.less';
import RecommendedProductRow from './recommended_product_row';

export const TRANSITION_MS = 300;

interface ProductDetailGridProps {
  productFamily: ProductFamily;
  products: Product[];
}

export default class ProductDetailGrid extends React.Component<ProductDetailGridProps, {}> {
  get NonColorOptionNames() {
    const { productFamily } = this.props;
    const options = productFamily.options.map((option) => option.name.toLowerCase());
    return options.filter((option) => option !== 'color');
  }

  get HasColor() {
    const { productFamily } = this.props;
    return productFamily.options.some((option) => option.name.toLowerCase() === 'color');
  }

  render() {
    const { products } = this.props;

    return (
      <div className={styles.table}>
        <div className={styles.thead}>
          <div className={styles.row}>
            <div className={styles.cell}>{this.HasColor && 'color'}</div>
            {this.NonColorOptionNames.map((option) => (
              <div className={styles.cell} key={option}>
                {option}
              </div>
            ))}
            <div className={styles.cell}>Price</div>
            <div className={classNames(styles.cell)}>Quantity</div>
          </div>
        </div>
        <div>
          {products.map((product) => (
            <RecommendedProductRow product={product} key={product.sku} />
          ))}
        </div>
      </div>
    );
  }
}
